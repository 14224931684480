import {
  Box,
  Center,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Skeleton,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import PlayerRoleIcon from 'components/PlayerRoleIcon'
import TeamIcon from 'components/TeamIcon'
import Web3Button from 'components/Web3Button'
import { getRoleName, teamIdToName } from 'constants/team'
import useBuyPlayers from 'hooks/useBuyPlayers'
import useReadPlayers from 'hooks/useReadPlayers'
import useReadPlayersBalance from 'hooks/useReadPlayersBalance'
import useReadPlayersPrice from 'hooks/useReadPlayersPrice'
import useReadPlayersSupply from 'hooks/useReadPlayersSupply'
import useSellPlayers from 'hooks/useSellPlayers'
import React, { useCallback, useEffect } from 'react'
import { FaPeopleGroup, FaStore, FaTrophy } from 'react-icons/fa6'
import { Player } from 'types'
import { formatAmountString } from 'utils/format'
import { formatEther } from 'viem'

const Market = () => {
  const { isLoading, players } = useReadPlayers()
  const playerIds = players?.map((player) => player.playerId) ?? []

  const { refetchPlayersSupply, supplyPerPlayerId } = useReadPlayersSupply({
    playerIds
  })

  const { balancePerPlayerId, refetchPlayersBalance } = useReadPlayersBalance({
    playerIds
  })

  const { isLoadingPlayersPrice, pricePerPlayerId, refetchPlayersPrice } =
    useReadPlayersPrice({
      playerIds
    })

  const refetchAll = useCallback(() => {
    refetchPlayersSupply()
    refetchPlayersBalance()
    refetchPlayersPrice()
  }, [refetchPlayersSupply, refetchPlayersBalance, refetchPlayersPrice])

  if (isLoading) {
    return (
      <Center p={8}>
        <Spinner />
      </Center>
    )
  }

  return (
    <Box p={4} pb="72px">
      <VStack spacing={8} mb="80px">
        <Heading my={8} textAlign="center">
          Onchain Fantasy LoL
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: 12, md: 4 }}>
          <VStack spacing={2}>
            <Icon as={FaStore} boxSize={12} mb={2} color="whiteAlpha.700" />
            <Heading size="md">Buy and sell players</Heading>
            <Text textAlign="center" textColor="whiteAlpha.700">
              The price of the players is calculated by the contract based on a
              bonding curve.
            </Text>
          </VStack>
          <VStack spacing={2}>
            <Icon
              as={FaPeopleGroup}
              boxSize={12}
              mb={2}
              color="whiteAlpha.700"
            />
            <Heading size="md">Build your team</Heading>
            <Text textAlign="center" textColor="whiteAlpha.700">
              Draft players for each phase of the Worlds 2023: each round of the
              swiss phase, quarterfinals, semifinals and final.
            </Text>
          </VStack>
          <VStack spacing={2}>
            <Icon as={FaTrophy} boxSize={12} mb={2} color="whiteAlpha.700" />
            <Heading size="md">Win rewards</Heading>
            <Text textAlign="center" textColor="whiteAlpha.700">
              Compete for a share of the trading fees: 5% of the fees generated
              during each round goes to the prize pool.
            </Text>
          </VStack>
        </SimpleGrid>
      </VStack>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Player</Th>
              <Th>Team</Th>
              <Th>Position</Th>
              <Th isNumeric>Buy Price</Th>
              <Th isNumeric>Sell Price</Th>
              <Th isNumeric>Total Supply</Th>
              <Th isNumeric>You own</Th>
              <Th textAlign="right">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {players?.map((player, i) => {
              return (
                <PlayerRow
                  key={i}
                  player={player}
                  supply={supplyPerPlayerId?.[player.playerId]}
                  balance={balancePerPlayerId?.[player.playerId]}
                  buyPriceAfterFee={
                    pricePerPlayerId?.[player.playerId].buyPriceAfterFee
                  }
                  sellPriceAfterFee={
                    pricePerPlayerId?.[player.playerId].sellPriceAfterFee
                  }
                  onBuySuccess={refetchAll}
                  onSellSuccess={refetchAll}
                  isLoadingPrices={isLoadingPlayersPrice}
                />
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

interface PlayerRowProps {
  isLoadingPrices: boolean
  onBuySuccess: () => void
  onSellSuccess: () => void
  player: Player
  balance?: number
  buyPriceAfterFee?: bigint
  sellPriceAfterFee?: bigint
  supply?: number
}

const PlayerRow = ({
  balance,
  buyPriceAfterFee,
  isLoadingPrices,
  onBuySuccess,
  onSellSuccess,
  player,
  sellPriceAfterFee,
  supply
}: PlayerRowProps) => {
  const {
    isLoading: isBuying,
    receipt: buyReceipt,
    write: buy
  } = useBuyPlayers({
    amount: 1,
    buyPriceAfterFee,
    playerId: player.playerId
  })

  const {
    isLoading: isSelling,
    receipt: sellReceipt,
    write: sell
  } = useSellPlayers({
    amount: 1,
    playerId: player.playerId,
    sellPriceAfterFee
  })
  const isSellDisabled = balance === 0 || supply === 1

  useEffect(() => {
    if (buyReceipt && buyReceipt.status === 'success') {
      onBuySuccess()
    }
  }, [buyReceipt, onBuySuccess])

  useEffect(() => {
    if (sellReceipt && sellReceipt.status === 'success') {
      onSellSuccess()
    }
  }, [onSellSuccess, sellReceipt])

  return (
    <Tr>
      <Td fontWeight="bold">{player.name}</Td>
      <Td>
        <HStack>
          <TeamIcon teamId={player.teamId} boxSize={6} />
          <Text>{teamIdToName[player.teamId]}</Text>
        </HStack>
      </Td>
      <Td>
        <HStack>
          <PlayerRoleIcon role={player.role} boxSize={5} />
          <Text>{getRoleName(player.role)}</Text>
        </HStack>
      </Td>
      <Td isNumeric>
        <Skeleton isLoaded={!isLoadingPrices}>
          {buyPriceAfterFee !== undefined
            ? formatAmountString(formatEther(buyPriceAfterFee))
            : '--'}{' '}
          ETH
        </Skeleton>
      </Td>
      <Td isNumeric>
        <Skeleton isLoaded={!isLoadingPrices}>
          {sellPriceAfterFee !== undefined
            ? formatAmountString(formatEther(sellPriceAfterFee))
            : '0'}{' '}
          ETH
        </Skeleton>
      </Td>
      <Td isNumeric>
        <Skeleton isLoaded={supply !== undefined}>{supply}</Skeleton>
      </Td>
      <Td isNumeric>
        <Skeleton isLoaded={balance !== undefined}>{balance}</Skeleton>
      </Td>
      <Td>
        <HStack justify="flex-end">
          <Web3Button
            colorScheme="green"
            isLoading={isBuying}
            onClick={() => buy()}
          >
            Buy
          </Web3Button>
          <Web3Button
            colorScheme="red"
            isDisabled={isSellDisabled}
            isLoading={isSelling}
            onClick={() => sell()}
          >
            Sell
          </Web3Button>
        </HStack>
      </Td>
    </Tr>
  )
}

export default Market
