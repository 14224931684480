import { FantasyLeagueABI } from 'abis/fantasyLeagueAbi'
import { FANTASY_LEAGUE_CONTRACT_ADDRESS } from 'addresses'
import { useContractWrite, useWaitForTransaction } from 'wagmi'

interface UseClaimPrizeProps {
  round?: number
}

export const useClaimPrize = ({ round }: UseClaimPrizeProps) => {
  const { data, isLoading, write } = useContractWrite({
    abi: FantasyLeagueABI,
    address: FANTASY_LEAGUE_CONTRACT_ADDRESS,
    args: round ? [BigInt(round)] : undefined,
    functionName: 'claimPrize'
  })

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransaction({ hash: data?.hash })

  return {
    isLoading: isLoading || isWaitingForTransaction,
    receipt,
    write
  }
}

export const useReadPrizePoolDistribution = {}
