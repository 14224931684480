import { PlayersABI } from 'abis/playersAbi'
import { PLAYERS_CONTRACT_ADDRESS } from 'addresses'
import { chain } from 'config'
import { useMemo } from 'react'
import { useContractReads } from 'wagmi'

const useReadPlayersPrice = ({ playerIds }: { playerIds: number[] }) => {
  const contract = {
    abi: PlayersABI,
    address: PLAYERS_CONTRACT_ADDRESS
  }

  const {
    data: prices,
    isLoading: isLoadingPlayersPrice,
    refetch: refetchPlayersPrice
  } = useContractReads({
    contracts: [
      ...playerIds.map((id) => ({
        ...contract,
        args: [BigInt(id), BigInt(1)],
        chainId: chain.id,
        functionName: 'getBuyPriceAfterFee'
      })),
      ...playerIds.map((id) => ({
        ...contract,
        args: [BigInt(id), BigInt(1)],
        chainId: chain.id,
        functionName: 'getSellPriceAfterFee'
      }))
    ],
    enabled: playerIds.length > 0
  })

  const pricePerPlayerId = useMemo(() => {
    const results = {} as {
      [playerId: number]: {
        buyPriceAfterFee: bigint
        sellPriceAfterFee?: bigint
      }
    }
    playerIds.forEach((playerId, i) => {
      results[playerId] = {
        buyPriceAfterFee: prices?.[i]?.result as bigint,
        sellPriceAfterFee: prices?.[i + playerIds.length]?.result as
          | bigint
          | undefined
      }
    })
    return results
  }, [playerIds, prices])

  return {
    isLoadingPlayersPrice,
    pricePerPlayerId,
    refetchPlayersPrice
  }
}

export default useReadPlayersPrice
