import { FantasyLeagueABI } from 'abis/fantasyLeagueAbi'
import { FANTASY_LEAGUE_CONTRACT_ADDRESS } from 'addresses'
import { chain } from 'config'
import { useMemo } from 'react'
import { getAddress } from 'viem'
import { useContractRead, useContractReads } from 'wagmi'

export const useReadCurrentEpoch = () => {
  return useContractRead({
    abi: FantasyLeagueABI,
    address: FANTASY_LEAGUE_CONTRACT_ADDRESS,
    chainId: chain.id,
    functionName: 'currentEpoch'
  })
}

export const useReadFantasyLeagueState = () => {
  const { data: currentEpoch, isLoading: isLoadingCurrentEpoch } =
    useReadCurrentEpoch()

  const { data: reads, isLoading: isLoadingFantasyLeagueState } =
    useContractReads({
      contracts: [
        {
          abi: FantasyLeagueABI,
          address: FANTASY_LEAGUE_CONTRACT_ADDRESS,
          chainId: chain.id,
          functionName: 'canEditRoaster'
        },
        {
          abi: FantasyLeagueABI,
          address: FANTASY_LEAGUE_CONTRACT_ADDRESS,
          args: currentEpoch ? [currentEpoch] : undefined,
          chainId: chain.id,
          functionName: 'canEditRoasterLimitTimestamps'
        }
      ],
      enabled: !!currentEpoch
    })

  const canEditRoaster = reads?.[0].result || false
  const canEditRoasterLimit = reads?.[1].result || BigInt(0)

  return {
    canEditRoaster,
    canEditRoasterLimit,
    currentEpoch,
    isLoading: isLoadingFantasyLeagueState || isLoadingCurrentEpoch
  }
}

interface UseReadPrizePoolProps {
  users: string[]
  round?: number
}

export const useReadPrizePool = ({ round, users }: UseReadPrizePoolProps) => {
  const contract = {
    abi: FantasyLeagueABI,
    address: FANTASY_LEAGUE_CONTRACT_ADDRESS
  }
  const { data, isLoading } = useContractReads({
    contracts: [
      {
        ...contract,
        args: round ? [BigInt(round)] : undefined,
        chainId: chain.id,
        functionName: 'prizePools'
      },
      ...users.map((user) => ({
        ...contract,
        args: round ? [BigInt(round), getAddress(user)] : undefined,
        chainId: chain.id,
        functionName: 'prizePoolDistributions'
      }))
    ],
    enabled: !!round
  })

  const prizePool = useMemo(
    () => data?.[0]?.result as bigint | undefined,
    [data]
  )

  const rewardsPerUser = useMemo(() => {
    if (!prizePool) return {}

    const results = {} as { [user: string]: bigint | undefined }
    users.forEach((user, i) => {
      const percent = data?.[i + 1]?.result as bigint | undefined
      if (percent) {
        results[user] = (prizePool * percent) / BigInt(100)
      }
    })

    return results
  }, [data, prizePool, users])

  return {
    isLoading,
    prizePool,
    rewardsPerUser
  }
}
