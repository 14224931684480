import { HStack, Tab, TabList, Tabs, Text } from '@chakra-ui/react'
import React from 'react'

interface RoundToggleButtonProps {
  maxRound: number
  round: number
  setRound: (round: number) => void
}

const RoundToggleButton = ({
  maxRound,
  round,
  setRound
}: RoundToggleButtonProps) => {
  const epochs = [...Array(maxRound).keys()].map((i) => i + 1)

  return (
    <HStack
      border="1px solid"
      borderColor="whiteAlpha.200"
      px={4}
      borderRadius="2xl"
      w="fit-content"
    >
      <Text>Round:</Text>
      <Tabs
        variant="solid-rounded"
        colorScheme="gray"
        index={round - 1}
        onChange={(index) => setRound(index + 1)}
      >
        <TabList p={0}>
          {epochs.map((epoch) => (
            <Tab key={epoch}>{epoch}</Tab>
          ))}
        </TabList>
      </Tabs>
    </HStack>
  )
}

export default RoundToggleButton
