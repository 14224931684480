import { ArrowBackIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import MyPlayersTable from 'components/MyPlayersTable'
import PlayerRoleIcon from 'components/PlayerRoleIcon'
import { teamIdToName } from 'constants/team'
import useSetRoaster from 'hooks/useSetRoaster'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Player, PlayerRole } from 'types'

interface PlayerCardProps {
  role: PlayerRole
  player?: Player
}

const PlayerCard = ({ player, role }: PlayerCardProps) => {
  return (
    <Center
      w="120px"
      border="1px solid"
      borderColor="whiteAlpha.200"
      borderRadius="lg"
      p={4}
      pos="relative"
    >
      <PlayerRoleIcon role={role} boxSize="30px" />
      {player ? (
        <Center
          pos="absolute"
          bg="blackAlpha.700"
          top={0}
          right={0}
          left={0}
          bottom={0}
          borderRadius="lg"
        >
          <VStack spacing={0}>
            <Text fontWeight="bold">{player.name}</Text>
            <Text textColor="gray.500">{teamIdToName[player.teamId]}</Text>
          </VStack>
        </Center>
      ) : null}
    </Center>
  )
}

const EditRoaster = () => {
  const navigate = useNavigate()

  const [roaster, setRoaster] = useState<{ [key in PlayerRole]?: Player }>(
    {} as { [key in PlayerRole]?: Player }
  )

  // ensure no more than 2 players from same team
  const teamIds = Object.values(roaster).reduce(
    (acc, curr) => {
      if (!curr) return acc
      const teamId = curr.teamId
      acc[teamId] = (acc[teamId] ?? 0) + 1
      return acc
    },
    {} as { [key: number]: number }
  )
  const isRoasterValid = Object.values(teamIds).every((count) => count <= 2)

  const {
    isLoading: isSettingRoaster,
    isRoasterFull,
    receipt: writeRoasterReceipt,
    write: writeRoaster
  } = useSetRoaster({
    roaster
  })

  return (
    <VStack p={4} alignItems="flex-start" spacing="80px">
      <VStack
        w="full"
        pb={{ base: '136px', md: '80px' }}
        align="flex-start"
        spacing={4}
      >
        <HStack>
          <IconButton
            aria-label="back"
            icon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
          />
          <Heading size="lg" w="full">
            <Heading size="lg">Edit Roaster</Heading>
          </Heading>
        </HStack>
        <MyPlayersTable
          roaster={roaster}
          onSelectPlayerClick={(player) =>
            setRoaster((curr) => ({
              ...curr,
              [player.role]: player
            }))
          }
        />
      </VStack>

      <Box w="full" bg="gray.900" pos="fixed" bottom={0} left={0} right={0}>
        <Flex
          p={4}
          gap={2}
          flexDir={{ base: 'column', md: 'row' }}
          align="center"
        >
          <Grid
            templateColumns="1fr 1fr 1fr 1fr 1fr"
            gap={{ base: 2, md: 4 }}
            overflow="scroll"
          >
            <PlayerCard
              role={PlayerRole.Top}
              player={roaster[PlayerRole.Top]}
            />
            <PlayerCard
              role={PlayerRole.Jungle}
              player={roaster[PlayerRole.Jungle]}
            />
            <PlayerCard
              role={PlayerRole.Mid}
              player={roaster[PlayerRole.Mid]}
            />
            <PlayerCard
              role={PlayerRole.Bot}
              player={roaster[PlayerRole.Bot]}
            />
            <PlayerCard
              role={PlayerRole.Support}
              player={roaster[PlayerRole.Support]}
            />
          </Grid>
          <Spacer />
          {writeRoasterReceipt && writeRoasterReceipt.status === 'success' ? (
            <Button onClick={() => navigate('/play')}>View Roaster</Button>
          ) : (
            <Button
              alignSelf="center"
              isDisabled={!isRoasterFull || !isRoasterValid}
              isLoading={isSettingRoaster}
              onClick={() => writeRoaster()}
              loadingText="Setting roaster"
            >
              {!isRoasterValid
                ? "You can't have more than 2 players from the same team"
                : !isRoasterFull
                ? 'Select all 5 players'
                : 'Submit Roaster'}
            </Button>
          )}
        </Flex>
      </Box>
    </VStack>
  )
}

export default EditRoaster
