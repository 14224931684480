import { PlayerRole } from 'types'

export const teamIdToName: { [teamId: number]: string } = {
  1: 'Gen.G',
  10: 'Fnatic',
  11: 'MAD Lions',
  12: 'NRG',
  13: 'Cloud9',
  14: 'Team Liquid',
  15: 'GAM',
  16: 'BDS',
  2: 'T1',
  3: 'KT Rolster',
  4: 'Dplus',
  5: 'JD Gaming',
  6: 'Bilibili Gaming',
  7: 'LNG Esports',
  8: 'Weibo Gaming',
  9: 'G2 Esports'
}

export const getRoleName = (role: PlayerRole): string => {
  switch (role) {
    case PlayerRole.Top:
      return 'Top'
    case PlayerRole.Jungle:
      return 'Jungler'
    case PlayerRole.Mid:
      return 'Mid'
    case PlayerRole.Bot:
      return 'Bot'
    case PlayerRole.Support:
      return 'Support'
  }
}

export const ROUND_INFO: { [round: number]: { date: string; name: string } } = {
  1: {
    date: 'Oct 19',
    name: 'Swiss Stage - R1'
  },
  2: {
    date: 'Oct 20',
    name: 'Swiss Stage - R2'
  },
  3: {
    date: 'Oct 21 to 23',
    name: 'Swiss Stage - R3'
  },
  4: {
    date: 'Oct 26 to 28',
    name: 'Swiss Stage - R4'
  },
  5: {
    date: 'Oct 29',
    name: 'Swiss Stage - R5'
  },
  6: {
    date: 'Nov 2 to 5',
    name: 'Quarterfinals'
  },
  7: {
    date: 'Nov 11 to 12',
    name: 'Semifinals'
  },
  8: {
    date: 'Nov 19',
    name: 'Finals'
  }
}
