import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: '1:884114845957:web:3217f17ac6a3b4e626076c',
  authDomain: 'fantasy-league-prod-f83f3.firebaseapp.com',
  messagingSenderId: '884114845957',
  projectId: 'fantasy-league-prod-f83f3',
  storageBucket: 'fantasy-league-prod-f83f3.appspot.com'
}

const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
