export const getCountdown = (timestampInSeconds: number): string => {
  const distance = timestampInSeconds - Math.floor(Date.now() / 1000)

  const days = Math.floor(distance / (60 * 60 * 24))
  const hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60))
  const minutes = Math.floor((distance % (60 * 60)) / 60)
  const seconds = Math.floor(distance % 60)

  if (days >= 1) {
    return `${days}d ${hours}h`
  }

  if (hours >= 1) {
    return `${hours}h ${minutes}m`
  }

  return `${minutes}m ${seconds}s`
}
