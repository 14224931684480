import { doc, getDoc } from 'firebase/firestore'
import { db } from 'firebaseClient'
import { useCallback, useEffect, useState } from 'react'
import { Roaster } from 'types'
import { getAddress } from 'viem'

interface UseGetRoasterByUserProps {
  enabled?: boolean
  round?: bigint
  user?: string
}

const useGetRoasterByUser = ({
  enabled = true,
  round,
  user
}: UseGetRoasterByUserProps) => {
  const [roaster, setRoaster] = useState<Roaster | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const fetch = useCallback(async () => {
    if (!enabled || !round || !user) return

    setIsLoading(true)

    const ref = doc(db, 'roasters', round.toString(), 'users', getAddress(user))

    getDoc(ref)
      .then((querySnapshot) => {
        if (!querySnapshot.exists()) return
        setRoaster(querySnapshot.data() as Roaster)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [round, enabled, user])

  useEffect(() => {
    fetch()
  }, [fetch])

  return {
    isLoading,
    roaster
  }
}

export default useGetRoasterByUser
