import { Box, Center, Show } from '@chakra-ui/react'
import NavigationBar from 'components/NavigationBar'
import TabBar from 'components/TabBar'
import EditRoaster from 'pages/EditRoaster'
import Leaderboard from 'pages/Leaderboard'
import Market from 'pages/Market'
import Play from 'pages/Play'
import * as React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

export const App = () => {
  return (
    <BrowserRouter>
      <NavigationBar />

      <Center>
        <Box w="full" maxW="2000px">
          <Routes>
            <Route path="/" element={<Market />} />

            <Route path="/market" element={<Market />} />
            <Route path="/play" element={<Play />} />
            <Route path="/play/edit" element={<EditRoaster />} />
            <Route path="/leaderboard" element={<Leaderboard />} />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
      </Center>

      <Show below="md">
        <TabBar />
      </Show>
    </BrowserRouter>
  )
}
