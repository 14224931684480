import { PlayersABI } from 'abis/playersAbi'
import { PLAYERS_CONTRACT_ADDRESS } from 'addresses'
import { chain } from 'config'
import { useMemo } from 'react'
import { useContractReads } from 'wagmi'

const useReadPlayersSupply = ({ playerIds }: { playerIds: number[] }) => {
  const contract = {
    abi: PlayersABI,
    address: PLAYERS_CONTRACT_ADDRESS
  }

  const {
    data: playersSupply,
    isLoading: isLoadingPlayersSupply,
    refetch: refetchPlayersSupply
  } = useContractReads({
    contracts: playerIds.map((id) => ({
      ...contract,
      args: [BigInt(id)],
      chainId: chain.id,
      functionName: 'playersSupply'
    })),
    enabled: playerIds.length > 0
  })

  const supplyPerPlayerId = useMemo(() => {
    return playersSupply?.reduce(
      (acc, result, i) => {
        switch (result.status) {
          case 'success':
            const supply = result.result as bigint
            acc[playerIds[i]] = Number(supply)
            break
          case 'failure':
            break
        }
        return acc
      },
      {} as { [playerId: number]: number }
    )
  }, [playersSupply, playerIds])

  return {
    isLoadingPlayersSupply,
    refetchPlayersSupply,
    supplyPerPlayerId
  }
}

export default useReadPlayersSupply
