import { Badge } from '@chakra-ui/react'
import React from 'react'

interface ScoreBadgeProps {
  score: number
}

const ScoreBadge = ({ score }: ScoreBadgeProps) => {
  return (
    <Badge
      bg={
        score >= 80
          ? 'red.500'
          : score >= 60
          ? 'orange.500'
          : score >= 40
          ? 'yellow.500'
          : 'gray.700'
      }
    >
      {score}
    </Badge>
  )
}

export default ScoreBadge
