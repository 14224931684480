import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter
} from 'firebase/firestore'
import { db } from 'firebaseClient'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Roaster } from 'types'

interface UseGetLeaderboardProps {
  enabled?: boolean
  round?: number
}

const useGetLeaderboard = ({
  enabled = true,
  round
}: UseGetLeaderboardProps) => {
  const [roasters, setRoasters] = useState<Roaster[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const lastDocRef = useRef<QueryDocumentSnapshot | undefined>()
  const [hasMore, setHasMore] = useState<boolean>(false)
  const pageSize = 20

  const fetchRoasters = useCallback(
    async (lastDoc?: QueryDocumentSnapshot) => {
      if (!enabled || !round) return

      setIsLoading(true)

      const queryConstraints: QueryConstraint[] = [
        orderBy('score.total', 'desc'),
        limit(pageSize)
      ]

      if (lastDoc) {
        queryConstraints.push(startAfter(lastDoc))
      }

      const q = query(
        collection(db, 'roasters', `${round}`, 'users'),
        ...queryConstraints
      )

      getDocs(q)
        .then((querySnapshot) => {
          const data: Roaster[] = []
          querySnapshot.forEach((doc) => {
            data.push(doc.data() as Roaster)
          })
          if (lastDoc) {
            setRoasters((prev) => [...prev, ...data])
          } else {
            setRoasters(data)
          }
          lastDocRef.current = querySnapshot.docs[querySnapshot.docs.length - 1]
          setHasMore(querySnapshot.docs.length === pageSize)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [round, enabled]
  )

  useEffect(() => {
    fetchRoasters()
  }, [fetchRoasters])

  return {
    fetchNextPage: useCallback(() => {
      fetchRoasters(lastDocRef.current)
    }, [fetchRoasters]),
    hasMore,
    isLoading,
    roasters
  }
}

export default useGetLeaderboard
