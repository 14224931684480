import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Heading,
  HStack,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList
} from '@chakra-ui/react'
import {
  BOOKER_CONTRACT_ADDRESS,
  FANTASY_LEAGUE_CONTRACT_ADDRESS,
  PLAYERS_CONTRACT_ADDRESS
} from 'addresses'
import React from 'react'
import { shortenString } from 'utils/format'

interface GameRulesModalProps {
  isOpen: boolean
  onClose: () => void
}

const GameRulesModal = ({ isOpen, onClose }: GameRulesModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent maxH="90vh" overflowY="scroll">
        <ModalHeader>Game Rules</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading size="sm" mb={2}>
            1. Trading
          </Heading>
          <Text>Each player purchase comes with a 10% trading fee:</Text>
          <UnorderedList mb={2}>
            <ListItem>
              5% of the fee contributes to the prize pool of the current round.
            </ListItem>
            <ListItem>The remaining 5% is allocated to the protocol.</ListItem>
          </UnorderedList>
          <Text mb={8}>
            You can't sell a player when in your active roaster.
          </Text>

          <Heading size="sm" mb={2}>
            2. Schedule
          </Heading>
          <Text>
            The first season starts with the World 2023 Swiss stage on October
            19. This includes:
          </Text>
          <UnorderedList mb={8}>
            <ListItem>5 rounds for the Swiss stage</ListItem>
            <ListItem>1 round for the quarterfinals</ListItem>
            <ListItem>1 round for the semifinals</ListItem>
            <ListItem>1 round for the final</ListItem>
          </UnorderedList>

          <Heading size="sm" mb={2}>
            3. Team Composition Rules
          </Heading>
          <Text mb={8}>
            Your team can't have more than 2 players from the same League of
            Legends team. 3 players will be allowed for the final.
          </Text>

          <Heading size="sm" mb={2}>
            4. Scoring
          </Heading>
          <UnorderedList mb={8}>
            <ListItem>
              In Best-of-Three or Best-of-Five formats, player scores are the
              average of their performance across the series.
            </ListItem>
            <ListItem>
              Your team's rank is determined by the combined scores of your
              roster players.
            </ListItem>
          </UnorderedList>

          <Heading size="sm" mb={2}>
            5. Rewards
          </Heading>
          <Text>Prize pool distribution per round:</Text>
          <UnorderedList mb={8}>
            <ListItem>1st Place: 50% of the prize pool.</ListItem>
            <ListItem>2nd Place: 30% of the prize pool.</ListItem>
            <ListItem>3rd Place: 20% of the prize pool.</ListItem>
          </UnorderedList>

          <Heading size="sm" mb={2}>
            6. Contracts
          </Heading>
          <UnorderedList mb={8}>
            <ListItem>
              <ContractLink name="Players" address={PLAYERS_CONTRACT_ADDRESS} />
            </ListItem>
            <ListItem>
              <ContractLink
                name="FantasyLeague"
                address={FANTASY_LEAGUE_CONTRACT_ADDRESS}
              />
            </ListItem>
            <ListItem>
              <ContractLink name="Booker" address={BOOKER_CONTRACT_ADDRESS} />
            </ListItem>
          </UnorderedList>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default GameRulesModal

const ContractLink = ({ address, name }: { address: string; name: string }) => {
  return (
    <HStack>
      <Text>{name}:</Text>
      <Link isExternal href={`https://basescan.org/address/${address}`}>
        <HStack>
          <Text>{shortenString(address)}</Text>
          <ExternalLinkIcon />
        </HStack>
      </Link>
    </HStack>
  )
}
