import { PlayersABI } from 'abis/playersAbi'
import { PLAYERS_CONTRACT_ADDRESS } from 'addresses'
import { useContractWrite, useWaitForTransaction } from 'wagmi'

interface UseBuyPlayersProps {
  amount: number
  playerId: number
  buyPriceAfterFee?: bigint
}

export const useBuyPlayers = ({
  amount,
  buyPriceAfterFee,
  playerId
}: UseBuyPlayersProps) => {
  const { data, isLoading, write } = useContractWrite({
    abi: PlayersABI,
    address: PLAYERS_CONTRACT_ADDRESS,
    args: [BigInt(playerId), BigInt(amount)],
    functionName: 'buyPlayers',
    value: buyPriceAfterFee
  })

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransaction({ hash: data?.hash })

  return {
    isLoading: isLoading || isWaitingForTransaction,
    receipt,
    write
  }
}

export default useBuyPlayers
