export const BookerABI = [
  {
    inputs: [],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'playerId',
        type: 'uint256'
      }
    ],
    name: 'Booker_IncorrectRole',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'playerId',
        type: 'uint256'
      }
    ],
    name: 'Booker_PlayerNotFound',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'playerId',
        type: 'uint256'
      }
    ],
    name: 'Booker_PlayerNotOwned',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'teamId',
        type: 'uint256'
      }
    ],
    name: 'Booker_TooManyPlayersFromSameTeam',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address'
      }
    ],
    name: 'OwnableInvalidOwner',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address'
      }
    ],
    name: 'OwnableUnauthorizedAccount',
    type: 'error'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'epoch',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'user',
        type: 'address'
      }
    ],
    name: 'ClearRoaster',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'epoch',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'user',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'topPlayerId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'junglePlayerId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'midPlayerId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'botPlayerId',
        type: 'uint256'
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'supportPlayerId',
        type: 'uint256'
      }
    ],
    name: 'SetRoaster',
    type: 'event'
  },
  {
    inputs: [],
    name: 'clearRoaster',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'fantasyLeague',
    outputs: [
      {
        internalType: 'contract FantasyLeague',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'maxPlayersFromSameTeam',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'players',
    outputs: [
      {
        internalType: 'contract Players',
        name: '',
        type: 'address'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'epoch',
        type: 'uint256'
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address'
      }
    ],
    name: 'roasters',
    outputs: [
      {
        internalType: 'uint256',
        name: 'topPlayerId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'junglePlayerId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'midPlayerId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'botPlayerId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'supportPlayerId',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_fantasyLeagueAddress',
        type: 'address'
      }
    ],
    name: 'setFantasyLeague',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_maxPlayersFromSameTeam',
        type: 'uint256'
      }
    ],
    name: 'setMaxPlayersFromSameTeam',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_playersAddress',
        type: 'address'
      }
    ],
    name: 'setPlayers',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_topPlayerId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_junglePlayerId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_midPlayerId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_botPlayerId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_supportPlayerId',
        type: 'uint256'
      }
    ],
    name: 'setRoaster',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
] as const
