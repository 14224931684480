import { ChakraProps, Icon } from '@chakra-ui/react'
import { ReactComponent as BotLogo } from 'assets/bot.svg'
import { ReactComponent as JungleLogo } from 'assets/jungle.svg'
import { ReactComponent as MidLogo } from 'assets/mid.svg'
import { ReactComponent as SupportLogo } from 'assets/support.svg'
import { ReactComponent as TopLogo } from 'assets/top.svg'
import React from 'react'
import { PlayerRole } from 'types'

interface PlayerRoleIconProps {
  role: PlayerRole
}

const PlayerRoleIcon = ({
  role,
  ...props
}: PlayerRoleIconProps & ChakraProps) => {
  switch (role) {
    case PlayerRole.Top:
      return <Icon as={TopLogo} {...props} />
    case PlayerRole.Jungle:
      return <Icon as={JungleLogo} {...props} />
    case PlayerRole.Mid:
      return <Icon as={MidLogo} {...props} />
    case PlayerRole.Bot:
      return <Icon as={BotLogo} {...props} />
    case PlayerRole.Support:
      return <Icon as={SupportLogo} {...props} />
  }
}

export default PlayerRoleIcon
