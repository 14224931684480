import { BookerABI } from 'abis/bookerAbi'
import { BOOKER_CONTRACT_ADDRESS } from 'addresses'
import { chain } from 'config'
import { useAccount, useContractRead } from 'wagmi'

interface UseReadRoasterProps {
  epoch?: bigint
}

const useReadRoaster = ({ epoch }: UseReadRoasterProps) => {
  const { address: account } = useAccount()

  return useContractRead({
    abi: BookerABI,
    address: BOOKER_CONTRACT_ADDRESS,
    args: account && epoch ? [epoch, account] : undefined,
    chainId: chain.id,
    enabled: !!account && !!epoch,
    functionName: 'roasters'
  })
}

export default useReadRoaster
