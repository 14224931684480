export enum PlayerRole {
  Top = 0,
  Jungle = 1,
  Mid = 2,
  Bot = 3,
  Support = 4
}

export type Player = {
  name: string
  playerId: number
  role: PlayerRole
  teamId: number
}

export type PlayersStats = {
  assists: number
  damageDealt: number
  deaths: number
  kills: number
  playerId: number
  playerName: string
  vision: number
  score?: number
}

export type Game = {
  epoch: number
  id: string
  teamIdA: number
  teamIdB: number
  playersStatsA?: PlayersStats[]
  playersStatsB?: PlayersStats[]
  teamATotalKills?: number
  teamBTotalKills?: number
  winnerTeamId?: number
}

type RoasterScore = {
  bot?: number
  jungle?: number
  mid?: number
  support?: number
  top?: number
  total?: number
}

export type Roaster = {
  botPlayerId: number
  epoch: number
  junglePlayerId: number
  midPlayerId: number
  supportPlayerId: number
  topPlayerId: number
  user: string
  score?: RoasterScore
}
