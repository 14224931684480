import { formatUnits } from 'viem'

export const shortenString = (
  string?: string,
  chars = 4
): string | undefined => {
  if (!string) return undefined
  return `${string.substring(0, chars + 2)}...${string.substring(
    string.length - chars
  )}`
}

export const formatAmount = (amount: bigint, decimals: number) => {
  return Number(formatUnits(amount, decimals)).toLocaleString()
}

export const formatAmountString = (
  amount: string,
  options: { places?: number; usd?: boolean } = { places: 5, usd: false }
) => {
  const numValue = Number(amount)
  const value = numValue.toLocaleString(undefined, {
    maximumFractionDigits: options.places
  })

  if (value === '0' && numValue > 0) {
    return options.usd ? '<$0.001' : '<0.001'
  }

  return options.usd ? `$${value}` : value
}
