import { getAddress } from 'viem'

export const PLAYERS_CONTRACT_ADDRESS = getAddress(
  process.env.REACT_APP_PLAYERS_CONTRACT_ADDRESS || ''
)

export const FANTASY_LEAGUE_CONTRACT_ADDRESS = getAddress(
  process.env.REACT_APP_FANTASY_LEAGUE_CONTRACT_ADDRESS || ''
)

export const BOOKER_CONTRACT_ADDRESS = getAddress(
  process.env.REACT_APP_BOOKER_CONTRACT_ADDRESS || ''
)
