import { ExternalLinkIcon, QuestionIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Link,
  Skeleton,
  Spacer,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack
} from '@chakra-ui/react'
import ClaimRewards from 'components/ClaimRewards'
import PlayerRoleIcon from 'components/PlayerRoleIcon'
import RoundToggleButton from 'components/RoundToggleButton'
import ScoreBadge from 'components/ScoreBadge'
import TeamIcon from 'components/TeamIcon'
import { ROUND_INFO } from 'constants/team'
import useGetLeaderboard from 'hooks/useGetLeaderboard'
import {
  useReadCurrentEpoch,
  useReadPrizePool
} from 'hooks/useReadFantasyLeagueContract'
import useReadPlayers from 'hooks/useReadPlayers'
import React, { useEffect } from 'react'
import { PlayerRole } from 'types'
import { formatAmountString, shortenString } from 'utils/format'
import { formatEther } from 'viem'

const PlayerScore = ({
  name,
  score,
  teamId
}: {
  name: string
  teamId: number
  score?: number
}) => {
  return (
    <HStack spacing={3}>
      <TeamIcon teamId={teamId} boxSize={6} />
      <Text>{name}</Text>
      {score ? <ScoreBadge score={score} /> : null}
    </HStack>
  )
}

const Leaderboard = () => {
  const { data: currentEpoch, isLoading: isLoadingCurrentEpoch } =
    useReadCurrentEpoch()
  const { isLoading: isLoadingPlayers, players } = useReadPlayers()

  const [round, setRound] = React.useState<number | undefined>()
  const {
    fetchNextPage,
    hasMore,
    isLoading: isLoadingLeaderboard,
    roasters
  } = useGetLeaderboard({
    round
  })

  const {
    isLoading: isLoadingPrizePool,
    prizePool,
    rewardsPerUser
  } = useReadPrizePool({
    round,
    users: roasters.slice(0, 10).map((roaster) => roaster.user)
  })

  useEffect(() => {
    if (currentEpoch) {
      setRound(Number(currentEpoch))
    }
  }, [currentEpoch])

  if (isLoadingPlayers || !players || isLoadingCurrentEpoch) {
    return (
      <Center p={8}>
        <Spinner />
      </Center>
    )
  }

  return (
    <VStack p={4} alignItems="flex-start" spacing={4} pb="72px">
      <Flex
        w="full"
        gap={{ base: 2, md: 4 }}
        flexDir={{ base: 'column', md: 'row' }}
      >
        <HStack gap={4}>
          <Heading size="lg">Leaderboard</Heading>
          <Tooltip label="Updated periodically after each game">
            <QuestionIcon boxSize="20px" />
          </Tooltip>
        </HStack>
        <Spacer />
        {round && currentEpoch ? (
          <RoundToggleButton
            round={round}
            setRound={setRound}
            maxRound={Number(currentEpoch)}
          />
        ) : null}
      </Flex>

      <Skeleton isLoaded={!!round && prizePool !== undefined}>
        <Text>
          Rewards for {ROUND_INFO[round || 1].name}:{' '}
          {formatAmountString(formatEther(prizePool || BigInt(0)), {
            places: 8
          })}{' '}
          ETH
        </Text>
      </Skeleton>

      {roasters.length === 0 && isLoadingLeaderboard ? (
        <Center w="full">
          <Spinner />
        </Center>
      ) : (
        <TableContainer w="full">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Users</Th>
                <Th>
                  <PlayerRoleIcon role={PlayerRole.Top} boxSize={5} />
                </Th>
                <Th>
                  <PlayerRoleIcon role={PlayerRole.Jungle} boxSize={5} />
                </Th>
                <Th>
                  <PlayerRoleIcon role={PlayerRole.Mid} boxSize={5} />
                </Th>
                <Th>
                  <PlayerRoleIcon role={PlayerRole.Bot} boxSize={5} />
                </Th>
                <Th>
                  <PlayerRoleIcon role={PlayerRole.Support} boxSize={5} />
                </Th>
                <Th isNumeric>
                  <HStack justify="flex-end">
                    <Text>Prize</Text>
                    <Tooltip label="Claimable when round has ended">
                      <QuestionIcon boxSize="14px" />
                    </Tooltip>
                  </HStack>
                </Th>
                <Th isNumeric>Score</Th>
              </Tr>
            </Thead>
            <Tbody>
              {roasters.map((roaster, i) => {
                const topPlayer = players.find(
                  (player) => player.playerId === Number(roaster.topPlayerId)
                )
                const junglePlayer = players.find(
                  (player) => player.playerId === Number(roaster.junglePlayerId)
                )
                const midPlayer = players.find(
                  (player) => player.playerId === Number(roaster.midPlayerId)
                )
                const botPlayer = players.find(
                  (player) => player.playerId === Number(roaster.botPlayerId)
                )
                const supportPlayer = players.find(
                  (player) =>
                    player.playerId === Number(roaster.supportPlayerId)
                )
                return (
                  <Tr key={i}>
                    <Td>{i + 1}</Td>
                    <Td>
                      <Link
                        isExternal
                        href={`https://basescan.org/address/${roaster.user}`}
                      >
                        <HStack>
                          <Text>{shortenString(roaster.user)}</Text>
                          <ExternalLinkIcon />
                        </HStack>
                      </Link>
                    </Td>
                    <Td>
                      <PlayerScore
                        name={topPlayer?.name || 'Top'}
                        score={roaster.score?.top}
                        teamId={topPlayer?.teamId || 0}
                      />
                    </Td>
                    <Td>
                      <PlayerScore
                        name={junglePlayer?.name || 'Jungler'}
                        score={roaster.score?.jungle}
                        teamId={junglePlayer?.teamId || 0}
                      />
                    </Td>
                    <Td>
                      <PlayerScore
                        name={midPlayer?.name || 'Mid'}
                        score={roaster.score?.mid}
                        teamId={midPlayer?.teamId || 0}
                      />
                    </Td>
                    <Td>
                      <PlayerScore
                        name={botPlayer?.name || 'Bot'}
                        score={roaster.score?.bot}
                        teamId={botPlayer?.teamId || 0}
                      />
                    </Td>
                    <Td>
                      <PlayerScore
                        name={supportPlayer?.name || 'Support'}
                        score={roaster.score?.support}
                        teamId={supportPlayer?.teamId || 0}
                      />
                    </Td>
                    <Td isNumeric>
                      <Skeleton isLoaded={!isLoadingPrizePool}>
                        <ClaimRewards
                          round={round}
                          currentRound={
                            currentEpoch ? Number(currentEpoch) : undefined
                          }
                          rewards={rewardsPerUser[roaster.user]}
                          user={roaster.user}
                        />
                      </Skeleton>
                    </Td>
                    <Td isNumeric>{roaster.score?.total || 0}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {hasMore ? (
        <Button
          alignSelf="center"
          isLoading={isLoadingLeaderboard}
          onClick={() => fetchNextPage()}
        >
          Load More
        </Button>
      ) : null}
    </VStack>
  )
}

export default Leaderboard
