import { extendTheme, ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false
}

const theme = extendTheme({
  colors: {
    accent: '#E50048'
  },
  config
  // styles: {
  //   global: () => ({
  //     'html, body': {
  //       // background: 'black'
  //     }
  //   })
  // }
})

export default theme
