import { Center, Icon, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { FaPeopleGroup, FaStore, FaTrophy } from 'react-icons/fa6'
import { NavLink, useLocation } from 'react-router-dom'

const TabBar = () => {
  const { pathname } = useLocation()

  const hideTabBar = pathname.startsWith('/play/edit')
  if (hideTabBar) return null

  return (
    <SimpleGrid
      pos="fixed"
      w="full"
      bottom={0}
      pb="env(safe-area-inset-bottom)"
      left={0}
      h="calc(56px + env(safe-area-inset-bottom))"
      columns={3}
      zIndex="sticky"
      bg="gray.800"
      borderTop="1px solid"
      borderTopColor="gray.800"
    >
      <TabBarItem
        icon={
          <Icon
            as={FaStore}
            boxSize="20px"
            color={
              pathname === '/' || pathname === '/market' ? 'accent' : 'white'
            }
          />
        }
        to="/"
        title="Market"
        isSelected={pathname === '/' || pathname === '/market'}
      />
      <TabBarItem
        icon={
          <Icon
            as={FaPeopleGroup}
            boxSize="20px"
            color={pathname === '/play' ? 'accent' : 'white'}
          />
        }
        to="/play"
        title="Play"
        isSelected={pathname === '/play'}
      />
      <TabBarItem
        icon={
          <Icon
            as={FaTrophy}
            boxSize="20px"
            color={pathname === '/leaderboard' ? 'accent' : 'white'}
          />
        }
        to="/leaderboard"
        title="Leaderboard"
        isSelected={pathname === '/leaderboard'}
      />
    </SimpleGrid>
  )
}

interface TabBarItemProps {
  icon: React.ReactNode
  isSelected: boolean
  title: string
  to: string
}

const TabBarItem = ({ icon, isSelected, title, to }: TabBarItemProps) => {
  return (
    <Center as={NavLink} h="full" to={to} opacity={isSelected ? '1' : '0.5'}>
      <VStack spacing={1}>
        {icon}
        <Text fontSize="xs">{title}</Text>
      </VStack>
    </Center>
  )
}

export default TabBar
