import { useToast } from '@chakra-ui/react'
import { PlayersABI } from 'abis/playersAbi'
import { PLAYERS_CONTRACT_ADDRESS } from 'addresses'
import { useContractWrite, useWaitForTransaction } from 'wagmi'

interface UseSellPlayersProps {
  amount: number
  playerId: number
  sellPriceAfterFee?: bigint
}

export const useSellPlayers = ({
  amount,
  playerId,
  sellPriceAfterFee
}: UseSellPlayersProps) => {
  const toast = useToast()
  const { data, isLoading, write } = useContractWrite({
    abi: PlayersABI,
    address: PLAYERS_CONTRACT_ADDRESS,
    args: [BigInt(playerId), BigInt(amount)],
    functionName: 'sellPlayers',
    onError: () => {
      toast({
        description:
          "You can't sell the last player when in your active roster.",
        duration: 5000,
        isClosable: true,
        position: 'top-right',
        status: 'error',
        title: 'Cannot sell players'
      })
    },
    value: sellPriceAfterFee
  })

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransaction({ hash: data?.hash })

  return {
    isLoading: isLoading || isWaitingForTransaction,
    receipt,
    write
  }
}

export default useSellPlayers
