import { Button, HStack, Text } from '@chakra-ui/react'
import { FantasyLeagueABI } from 'abis/fantasyLeagueAbi'
import { FANTASY_LEAGUE_CONTRACT_ADDRESS } from 'addresses'
import { useClaimPrize } from 'hooks/useClaimPrize'
import React from 'react'
import { formatAmountString } from 'utils/format'
import { formatEther } from 'viem'
import { useAccount, useContractRead } from 'wagmi'

interface ClaimRewardsProps {
  user: string
  currentRound?: number
  rewards?: bigint
  round?: number
}

const ClaimRewards = ({
  currentRound,
  rewards,
  round,
  user
}: ClaimRewardsProps) => {
  const { address: account } = useAccount()
  const isUser = account?.toLowerCase() === user.toLowerCase()
  const isCurrentRoundEnded =
    currentRound && round ? currentRound > round : false

  const { data: hasClaimed } = useContractRead({
    abi: FantasyLeagueABI,
    address: FANTASY_LEAGUE_CONTRACT_ADDRESS,
    args: account && round ? [BigInt(round), account] : undefined,
    enabled: isUser && !!round && !!account && isCurrentRoundEnded,
    functionName: 'hasClaimed'
  })

  const { isLoading: isClaimingPrize, write: claimPrize } = useClaimPrize({
    round
  })

  if (!rewards) {
    return <Text>--</Text>
  }

  return (
    <HStack justify="flex-end" spacing={4}>
      {isUser && isCurrentRoundEnded ? (
        <Button
          size="sm"
          onClick={() => claimPrize()}
          isLoading={isClaimingPrize}
          isDisabled={hasClaimed === true}
        >
          {hasClaimed === true ? 'Claimed!' : 'Claim'}
        </Button>
      ) : null}
      <Text>{formatAmountString(formatEther(rewards))} ETH</Text>
    </HStack>
  )
}

export default ClaimRewards
