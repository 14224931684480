import {
  Button,
  Center,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { getRoleName, teamIdToName } from 'constants/team'
import useReadPlayers from 'hooks/useReadPlayers'
import useReadPlayersBalance from 'hooks/useReadPlayersBalance'
import React from 'react'
import { Player, PlayerRole } from 'types'

import PlayerRoleIcon from './PlayerRoleIcon'
import TeamIcon from './TeamIcon'

interface MyPlayersTableProps {
  onSelectPlayerClick: (player: Player) => void
  roaster: { [key in PlayerRole]?: Player }
}

const MyPlayersTable = ({
  onSelectPlayerClick,
  roaster
}: MyPlayersTableProps) => {
  const { isLoading, players } = useReadPlayers()
  const playerIds = players?.map((player) => player.playerId) ?? []

  const { balancePerPlayerId, isLoadingPlayersBalance } = useReadPlayersBalance(
    { playerIds }
  )
  const ownedPlayers = balancePerPlayerId
    ? players?.filter((player) => {
        return balancePerPlayerId[player.playerId] > 0
      })
    : []

  if (isLoading || isLoadingPlayersBalance) {
    return (
      <Center p={8} w="full">
        <Spinner />
      </Center>
    )
  }

  return (
    <TableContainer w="full">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Player</Th>
            <Th>Team</Th>
            <Th>Position</Th>
            <Th isNumeric>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {ownedPlayers?.map((player, i) => {
            const isSelected =
              roaster[player.role]?.playerId === player.playerId
            return (
              <Tr key={i}>
                <Td>{player.name}</Td>
                <Td>
                  <HStack>
                    <TeamIcon teamId={player.teamId} boxSize={6} />
                    <Text>{teamIdToName[player.teamId]}</Text>
                  </HStack>
                </Td>
                <Td>
                  <HStack>
                    <PlayerRoleIcon role={player.role} boxSize={5} />
                    <Text>{getRoleName(player.role)}</Text>
                  </HStack>
                </Td>
                <Td isNumeric>
                  <Button
                    isDisabled={isSelected}
                    onClick={() => onSelectPlayerClick(player)}
                  >
                    {isSelected ? 'Selected!' : 'Select'}
                  </Button>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default MyPlayersTable
