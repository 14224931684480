import {
  Badge,
  Center,
  Heading,
  HStack,
  Icon,
  SimpleGrid,
  Skeleton,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import GamesTable from 'components/GamesTable'
import PlayerRoleIcon from 'components/PlayerRoleIcon'
import Web3Button from 'components/Web3Button'
import { getRoleName, ROUND_INFO, teamIdToName } from 'constants/team'
import useGetRoasterByUser from 'hooks/useGetRoasterByUser'
import { useReadFantasyLeagueState } from 'hooks/useReadFantasyLeagueContract'
import useReadPlayers from 'hooks/useReadPlayers'
import useReadRoaster from 'hooks/useReadRoaster'
import React, { useMemo } from 'react'
import { FaShuffle } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'
import { Player, PlayerRole } from 'types'
import { getCountdown } from 'utils/date'
import { useAccount } from 'wagmi'

interface PlayerCardProps {
  role: PlayerRole
  player?: Player & { score?: number }
}

const PlayerCard = ({ player, role }: PlayerCardProps) => {
  return (
    <Center
      aspectRatio={0.8}
      border="1px solid"
      borderColor="whiteAlpha.200"
      borderRadius="xl"
      p={4}
    >
      <VStack>
        <PlayerRoleIcon role={role} boxSize="80px" />
        <VStack spacing={0}>
          <Text fontWeight="bold" fontSize="xl">
            {player ? player.name : getRoleName(role)}
          </Text>
          {player ? (
            <>
              <Text textColor="gray.500">{teamIdToName[player.teamId]}</Text>
              <Text pt={4} fontWeight="bold">
                {player.score || 0} PTS
              </Text>
            </>
          ) : null}
        </VStack>
      </VStack>
    </Center>
  )
}

const Play = () => {
  const navigate = useNavigate()
  const { address: account } = useAccount()

  const {
    canEditRoaster,
    canEditRoasterLimit,
    currentEpoch,
    isLoading: isLoadingFantasyLeagueState
  } = useReadFantasyLeagueState()

  const { isLoading: isLoadingPlayers, players } = useReadPlayers()

  const { data: activePlayerIds, isLoading: isLoadingActiveRoaster } =
    useReadRoaster({ epoch: currentEpoch })

  const { roaster: firestoreRoaster } = useGetRoasterByUser({
    round: currentEpoch,
    user: account
  })

  const roaster: { [key in PlayerRole]?: Player & { score?: number } } =
    useMemo(() => {
      if (!players || !activePlayerIds) return {}
      const roaster = {} as {
        [key in PlayerRole]?: Player & { score?: number }
      }
      activePlayerIds.forEach((playerId) => {
        const player = players.find(
          (player) => player.playerId === Number(playerId)
        )
        if (!player) return

        let score: number | undefined
        switch (player.role) {
          case PlayerRole.Top:
            score = firestoreRoaster?.score?.top
            break
          case PlayerRole.Jungle:
            score = firestoreRoaster?.score?.jungle
            break
          case PlayerRole.Mid:
            score = firestoreRoaster?.score?.mid
            break
          case PlayerRole.Bot:
            score = firestoreRoaster?.score?.bot
            break
          case PlayerRole.Support:
            score = firestoreRoaster?.score?.support
            break
        }

        roaster[player.role] = { ...player, score }
      })
      return roaster
    }, [players, activePlayerIds, firestoreRoaster])

  if (
    isLoadingFantasyLeagueState ||
    isLoadingPlayers ||
    isLoadingActiveRoaster
  ) {
    return (
      <Center p={8}>
        <Spinner />
      </Center>
    )
  }

  return (
    <VStack p={4} alignItems="flex-start" pb={{ base: '72px', md: 4 }}>
      <VStack alignItems="flex-start" spacing={1} w="full" pb={4}>
        <HStack spacing={4}>
          <Heading size="lg">Roaster</Heading>
          {canEditRoaster ? (
            <Badge colorScheme="red">
              Starts in: {getCountdown(Number(canEditRoasterLimit))}
            </Badge>
          ) : (
            <Badge>Round in progress</Badge>
          )}
        </HStack>
        <Text>
          {canEditRoaster
            ? 'Your active roaster for the upcoming round:'
            : 'You have to wait for the current round to end before you can edit your roaster.'}
        </Text>
      </VStack>

      <VStack w="full" spacing={4} mb={{ base: 10, md: '80px' }}>
        {canEditRoaster ? (
          <Web3Button
            alignSelf="flex-end"
            leftIcon={<Icon as={FaShuffle} />}
            onClick={() => navigate('/play/edit')}
          >
            Edit Roaster
          </Web3Button>
        ) : null}
        <SimpleGrid columns={{ base: 2, xl: 5 }} spacing={4} w="full">
          <PlayerCard role={PlayerRole.Top} player={roaster[PlayerRole.Top]} />
          <PlayerCard
            role={PlayerRole.Jungle}
            player={roaster[PlayerRole.Jungle]}
          />
          <PlayerCard role={PlayerRole.Mid} player={roaster[PlayerRole.Mid]} />
          <PlayerCard role={PlayerRole.Bot} player={roaster[PlayerRole.Bot]} />
          <PlayerCard
            role={PlayerRole.Support}
            player={roaster[PlayerRole.Support]}
          />
        </SimpleGrid>
      </VStack>

      <VStack w="full" align="flex-start">
        <Skeleton isLoaded={currentEpoch !== undefined} mb={4}>
          <HStack align="center" spacing={4}>
            <Heading size="lg" w="full">
              {ROUND_INFO[Number(currentEpoch || BigInt(1))].name}
            </Heading>
            <Badge>{ROUND_INFO[Number(currentEpoch || BigInt(1))].date}</Badge>
          </HStack>
        </Skeleton>
        {currentEpoch ? <GamesTable round={Number(currentEpoch)} /> : null}
      </VStack>
    </VStack>
  )
}

export default Play
