import {
  Badge,
  Center,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { teamIdToName } from 'constants/team'
import useGetGames from 'hooks/useGetGames'
import { PlayersStats } from 'types'

import ScoreBadge from './ScoreBadge'
import TeamIcon from './TeamIcon'

interface GamesTableProps {
  round: number
}

const GamesTable = ({ round }: GamesTableProps) => {
  const { games, isLoading } = useGetGames({ round })

  if (isLoading) {
    return (
      <Center p={8} w="full">
        <Spinner />
      </Center>
    )
  }

  return (
    <TableContainer w="full">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Game</Th>
            <Th>Team A</Th>
            <Th>Team B</Th>
          </Tr>
        </Thead>
        <Tbody>
          {games.map((game, i) => {
            return (
              <Tr key={i}>
                <Td>
                  <VStack align="flex-start" spacing={3}>
                    <TeamInfo
                      teamId={game.teamIdA}
                      winnerTeamId={game.winnerTeamId}
                    />
                    <TeamInfo
                      teamId={game.teamIdB}
                      winnerTeamId={game.winnerTeamId}
                    />
                  </VStack>
                </Td>
                <Td>
                  {game.playersStatsA ? (
                    <PlayerStats playersStats={game.playersStatsA} />
                  ) : (
                    '--'
                  )}
                </Td>
                <Td>
                  {game.playersStatsB ? (
                    <PlayerStats playersStats={game.playersStatsB} />
                  ) : (
                    '--'
                  )}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>

      {games.length === 0 ? (
        <Center w="full" minH="200px">
          <Text textColor="whiteAlpha.500">
            Games will appear when the Swiss Stage is drawn
          </Text>
        </Center>
      ) : null}
    </TableContainer>
  )
}

const TeamInfo = ({
  teamId,
  winnerTeamId
}: {
  teamId: number
  winnerTeamId?: number
}) => {
  return (
    <HStack spacing={3}>
      <TeamIcon teamId={teamId} boxSize={6} />
      <Text
        fontWeight="bold"
        textColor={
          winnerTeamId === teamId || !winnerTeamId ? 'white' : 'whiteAlpha.500'
        }
      >
        {teamIdToName[teamId]}
      </Text>
      {winnerTeamId === teamId ? (
        <Badge colorScheme="green">Winner</Badge>
      ) : null}
    </HStack>
  )
}

const PlayerStats = ({ playersStats }: { playersStats: PlayersStats[] }) => {
  return (
    <HStack>
      {playersStats.map((playerStat, i) => (
        <HStack key={playerStat.playerId}>
          <Text>{playerStat.playerName}</Text>
          <ScoreBadge score={playerStat.score || 0} />
          {i < playersStats.length - 1 ? <Text>•</Text> : null}
        </HStack>
      ))}
    </HStack>
  )
}

export default GamesTable
