import {
  darkTheme,
  RainbowKitProvider as RainbowKitProviderDefault,
  Theme
} from '@rainbow-me/rainbowkit'
import { chain, wagmi } from 'config'
import React from 'react'

const defaultRainbowKitTheme = darkTheme({ accentColor: '#e50048' })
const theme: Theme = {
  ...defaultRainbowKitTheme,
  colors: {
    ...defaultRainbowKitTheme.colors,
    connectButtonBackground: '#2D3748',
    modalBackground: '#1A202C'
  },
  radii: {
    ...defaultRainbowKitTheme.radii,
    connectButton: '6px'
  }
}

interface RainbowKitProviderProps {
  children: React.ReactNode
}

const RainbowKitProvider = ({ children }: RainbowKitProviderProps) => {
  return (
    <RainbowKitProviderDefault
      chains={wagmi.chains}
      initialChain={chain}
      appInfo={{
        appName: 'stuck.gg'
      }}
      theme={theme}
      locale="en"
    >
      {children}
    </RainbowKitProviderDefault>
  )
}

export default RainbowKitProvider
