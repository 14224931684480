import { Image, ImageProps } from '@chakra-ui/react'
import BDSLogo from 'assets/teams/bds.webp'
import BLBGamingLogo from 'assets/teams/blb-gaming.png'
import Cloud9Logo from 'assets/teams/cloud9.png'
import DPlusLogo from 'assets/teams/dplus.png'
import FnaticLogo from 'assets/teams/fnatic.png'
import G2Logo from 'assets/teams/g2.png'
import GAMLogo from 'assets/teams/gam.webp'
import GenGLogo from 'assets/teams/geng.png'
import JDGLogo from 'assets/teams/jdg.png'
import KTLogo from 'assets/teams/kt.png'
import LNGLogo from 'assets/teams/lng.png'
import MadLionsLogo from 'assets/teams/mad-lions.png'
import NRGLogo from 'assets/teams/nrg.png'
import T1Logo from 'assets/teams/t1.png'
import TeamLiquidLogo from 'assets/teams/team-liquid.png'
import WeiboLogo from 'assets/teams/weibo.png'
import React from 'react'

interface TeamIconProps {
  teamId: number
}

const TeamIcon = ({ teamId, ...props }: TeamIconProps & ImageProps) => {
  let logo
  switch (teamId) {
    case 1:
      logo = GenGLogo
      break
    case 2:
      logo = T1Logo
      break
    case 3:
      logo = KTLogo
      break
    case 4:
      logo = DPlusLogo
      break
    case 5:
      logo = JDGLogo
      break
    case 6:
      logo = BLBGamingLogo
      break
    case 7:
      logo = LNGLogo
      break
    case 8:
      logo = WeiboLogo
      break
    case 9:
      logo = G2Logo
      break
    case 10:
      logo = FnaticLogo
      break
    case 11:
      logo = MadLionsLogo
      break
    case 12:
      logo = NRGLogo
      break
    case 13:
      logo = Cloud9Logo
      break
    case 14:
      logo = TeamLiquidLogo
      break
    case 15:
      logo = GAMLogo
      break
    case 16:
      logo = BDSLogo
      break
    default:
      logo = null
  }

  return logo ? <Image src={logo} objectFit="contain" {...props} /> : null
}

export default TeamIcon
