import {
  Button,
  Heading,
  Hide,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import AppLogoSrc from 'assets/app-logo.png'
import React from 'react'
import { FaListUl } from 'react-icons/fa6'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import GameRulesModal from './GameRulesModal'

const NavigationBar = () => {
  const location = useLocation()
  const {
    isOpen: isGameRulesModalOpen,
    onClose: onCloseGameRulesModal,
    onOpen: onOpenGameRulesModal
  } = useDisclosure()

  return (
    <HStack
      w="full"
      p={4}
      spacing={8}
      pos="sticky"
      top={0}
      bg="gray.800"
      zIndex="sticky"
      justify="space-between"
    >
      <HStack spacing={12}>
        <Link as={RouterLink} to="/market" _hover={{}}>
          <HStack spacing={4}>
            <Image src={AppLogoSrc} boxSize={8} />
            <Hide below="sm">
              <Heading size="md">stuck.gg</Heading>
            </Hide>
          </HStack>
        </Link>
        <Hide below="md">
          <HStack spacing={4}>
            <Link
              as={RouterLink}
              to="/market"
              _hover={{ bg: 'whiteAlpha.200' }}
              borderRadius="lg"
              px={4}
              py={2}
              opacity={location.pathname === '/market' ? 1 : 0.5}
            >
              Market
            </Link>
            <Link
              as={RouterLink}
              to="/play"
              _hover={{ bg: 'whiteAlpha.200' }}
              borderRadius="lg"
              px={4}
              py={2}
              opacity={location.pathname === '/play' ? 1 : 0.5}
            >
              Play
            </Link>
            <Link
              as={RouterLink}
              to="/leaderboard"
              _hover={{ bg: 'whiteAlpha.200' }}
              borderRadius="lg"
              px={4}
              py={2}
              opacity={location.pathname === '/leaderboard' ? 1 : 0.5}
            >
              Leaderboard
            </Link>
          </HStack>
        </Hide>
      </HStack>

      <HStack spacing={4}>
        <Button onClick={onOpenGameRulesModal}>
          <Hide below="sm">
            <Text>View Rules</Text>
          </Hide>
          <Hide above="sm">
            <Icon as={FaListUl} />
          </Hide>
        </Button>
        <ConnectButton showBalance={false} />
      </HStack>

      <GameRulesModal
        isOpen={isGameRulesModalOpen}
        onClose={onCloseGameRulesModal}
      />
    </HStack>
  )
}

export default NavigationBar
