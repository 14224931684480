import { BookerABI } from 'abis/bookerAbi'
import { BOOKER_CONTRACT_ADDRESS } from 'addresses'
import { Player, PlayerRole } from 'types'
import { useContractWrite, useWaitForTransaction } from 'wagmi'

interface UseSetRoasterProps {
  roaster: { [key in PlayerRole]?: Player }
}

const useSetRoaster = ({ roaster }: UseSetRoasterProps) => {
  const topId = roaster[PlayerRole.Top]?.playerId
  const jungleId = roaster[PlayerRole.Jungle]?.playerId
  const midId = roaster[PlayerRole.Mid]?.playerId
  const botId = roaster[PlayerRole.Bot]?.playerId
  const supportId = roaster[PlayerRole.Support]?.playerId

  const isRoasterFull =
    !!topId && !!jungleId && !!midId && !!botId && !!supportId

  const { data, isLoading, write } = useContractWrite({
    abi: BookerABI,
    address: BOOKER_CONTRACT_ADDRESS,
    args: isRoasterFull
      ? [
          BigInt(topId),
          BigInt(jungleId),
          BigInt(midId),
          BigInt(botId),
          BigInt(supportId)
        ]
      : undefined,
    functionName: 'setRoaster'
  })

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransaction({ hash: data?.hash })

  return {
    isLoading: isLoading || isWaitingForTransaction,
    isRoasterFull,
    receipt,
    write
  }
}

export default useSetRoaster
