import { Button, ButtonProps } from '@chakra-ui/react'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { chain } from 'config'
import React from 'react'
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi'

const Web3Button = (props: ButtonProps) => {
  const { isConnected } = useAccount()
  const { chain: walletChain } = useNetwork()
  const { openConnectModal } = useConnectModal()
  const { switchNetwork } = useSwitchNetwork()

  return (
    <Button
      {...props}
      onClick={
        !isConnected
          ? () => openConnectModal?.()
          : isConnected && walletChain?.unsupported
          ? () => switchNetwork?.(chain.id)
          : props.onClick
      }
    />
  )
}

export default Web3Button
