import { PlayersABI } from 'abis/playersAbi'
import { PLAYERS_CONTRACT_ADDRESS } from 'addresses'
import { chain } from 'config'
import { useMemo } from 'react'
import { Player } from 'types'
import { useContractReads } from 'wagmi'

const useReadPlayers = () => {
  const playersCount = 80
  const playerIds = [...Array(playersCount).keys()].map((i) => i + 1)

  const contract = {
    abi: PlayersABI,
    address: PLAYERS_CONTRACT_ADDRESS
  }

  const { data, isLoading } = useContractReads({
    contracts: playerIds.map((id) => ({
      ...contract,
      args: [BigInt(id)],
      chainId: chain.id,
      functionName: 'players'
    }))
  })

  const players = useMemo(() => {
    return data
      ?.map((result) => {
        switch (result.status) {
          case 'success':
            const info = result.result as [bigint, bigint, string, number]
            if (info[0] === BigInt(0)) {
              // ignore players with id 0 (means they don't exist)
              return undefined
            }
            return {
              name: info[2],
              playerId: Number(info[0]),
              role: info[3],
              teamId: Number(info[1])
            } as Player
          case 'failure':
            return undefined
        }
      })
      .filter(Boolean) as Player[] | undefined
  }, [data])

  return {
    isLoading,
    players
  }
}

export default useReadPlayers
