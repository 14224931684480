import { getDefaultWallets } from '@rainbow-me/rainbowkit'
import { base } from 'viem/chains'
import { configureChains, createConfig } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

const alchemyApiKey = process.env.REACT_APP_ALCHEMY_API_KEY || ''

export const chain = base

const { chains, publicClient } = configureChains(
  [chain],
  [alchemyProvider({ apiKey: alchemyApiKey }), publicProvider()],
  {
    batch: { multicall: { batchSize: 1_024 * 2 } }
  }
)

const { connectors } = getDefaultWallets({
  appName: 'stuck.gg',
  chains,
  projectId: process.env.REACT_APP_WC_PROJECT_ID || ''
})

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})

export const wagmi = {
  chains,
  config: wagmiConfig
}
