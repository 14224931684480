import { PlayersABI } from 'abis/playersAbi'
import { PLAYERS_CONTRACT_ADDRESS } from 'addresses'
import { chain } from 'config'
import { useMemo } from 'react'
import { useAccount, useContractReads } from 'wagmi'

const useReadPlayersBalance = ({ playerIds }: { playerIds: number[] }) => {
  const { address: account } = useAccount()

  const contract = {
    abi: PlayersABI,
    address: PLAYERS_CONTRACT_ADDRESS
  }

  const {
    data: playersBalance,
    isLoading: isLoadingPlayersBalance,
    refetch: refetchPlayersBalance
  } = useContractReads({
    contracts: playerIds.map((id) => ({
      ...contract,
      args: account ? [BigInt(id), account] : undefined,
      chainId: chain.id,
      functionName: 'playersBalance'
    })),
    enabled: !!account && playerIds.length > 0
  })

  const balancePerPlayerId = useMemo(() => {
    return playersBalance?.reduce(
      (acc, result, i) => {
        switch (result.status) {
          case 'success':
            const balance = result.result as bigint
            acc[playerIds[i]] = Number(balance)
            break
          case 'failure':
            break
        }
        return acc
      },
      {} as { [playerId: number]: number }
    )
  }, [playersBalance, playerIds])

  return {
    balancePerPlayerId,
    isLoadingPlayersBalance,
    refetchPlayersBalance
  }
}

export default useReadPlayersBalance
